import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from "js-cookie";

import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Spinner from '../../../components/bootstrap/Spinner';
import showNotification from '../../../components/extras/showNotification';
import { encrypt } from '../../../service/Cryptograph';

const logo = require('../../../assets/img/logo-dark.png');

const Login = () => {

	const navigate = useNavigate();
	const [email, setEmail] = useState('');
	const [passwordInput, setPasswordInput] = useState('');
	const [loading, setLoading] = useState(false);
	const token = Cookies.get("token");

	const handleOnClick = () => {
		setLoading(true);

		const data = {
			credential: email,
			password: passwordInput,
      captcha: ''
		}

		axios.post(`${process.env.REACT_APP_URL_API}login/v2`, data, {
			timeout: 6000
		})
		.then( response => {
			if(response.status===200){
				if(response.data.success){
					Cookies.set("token", encrypt(response.data.data.auth.token), { expires: 1 });
     			Cookies.set("refreshToken", encrypt(response.data.data.auth.refreshToken), { expires: 1 });
					Cookies.set("userdata", encrypt(JSON.stringify(response.data.data)), { expires: 1 });
          navigate('/')
				}else{
          showNotification('Log In Gagal', response.data.error, 'danger');
        }
			}else{
				showNotification('Log In Gagal', response.statusText, 'danger');
			}
      setLoading(false);
		})
		.catch(error => {
			setLoading(false);
			console.log('Response error ', error);
			showNotification('Log In Gagal', 'Oops! Terjadi kesalahan', 'danger');
		});
	}

	if(token){
    return <Navigate to={'/'}/>
  }

	return (
		<PageWrapper
			title='Login'
			className='bg-dark'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<img src={logo} alt='' style={{width:230}}/>
								</div>

								<div className='text-center h1 fw-bold mt-5'>Selamat Datang</div>
								<div className='text-center h5 text-muted mb-5'>Log in Untuk Melanjutkan</div>

								<form className='row g-4'>
									<div className='col-12'>
										<FormGroup
											id='login-username'
											isFloating
											label='Email'>
											<Input autoComplete='username' value={email} onChange={(e) => setEmail(e.target.value)}/>
										</FormGroup>
										<FormGroup
											className='mt-2'
											id='login-password'
											isFloating
											label='Password'>
											<Input
												type='password'
												autoComplete='password'
												value={passwordInput}
												onChange={(e) => setPasswordInput(e.target.value)}
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<Button
											color='danger'
											className='w-100 py-3'
											disabled={loading}
											onClick={handleOnClick}>
												{
													loading ?
														<div>
															<Spinner
																size={14}
																style={{marginRight:10}}
															/>
															Loading...
														</div> :
														<div>Login</div>
												}
										</Button>
									</div>
								</form>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Login;
