import React from 'react';
import { authPages } from '../menu';
import Footer from '../layout/Footer/Footer';

const footers = [
	{ path: authPages.login.path, element: null, exact: true },
	{ path: authPages.page404.path, element: null, exact: true },
	{ path: '*', element: <Footer /> },
];

export default footers;
