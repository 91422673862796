import React, { useEffect, useState } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import YouTube from 'react-youtube';

import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../layout/SubHeader/SubHeader';
import Button from '../../components/bootstrap/Button';
import Breadcrumb from '../../components/bootstrap/Breadcrumb';
import Page from '../../layout/Page/Page';
import Card, {
  CardActions,
	CardBody,
	CardFooter,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../components/bootstrap/Card';
import Input from '../../components/bootstrap/forms/Input';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Spinner from '../../components/bootstrap/Spinner';
import Alert, { AlertHeading } from '../../components/bootstrap/Alert';
import VideoInput from '../../components/VideoInput';
import ImageInput from '../../components/ImageInput';
import Select from '../../components/bootstrap/forms/Select';
import apiClient from '../../service/AxiosInstance';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../components/bootstrap/Modal';
import showNotification from '../../components/extras/showNotification';
import Checks, { ChecksGroup } from '../../components/bootstrap/forms/Checks';
import Label from '../../components/bootstrap/forms/Label';

const type = ['Upload', 'Youtube ID']
const videoPlaceholder = require('../../assets/img/video_player_placeholder.gif');

const VideoForm = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState({
    title:'',
    categorySelected: '',
    desc: '',
    period: '',
    price: '',
    cover_image: '',
    cover_video: ''
  })
  const [content, setContent] = useState([{
    subject: '',
    video: [
      {
        name: '',
        url: '',
        duration: null
      }
    ]
  }])
  const [category, setCategory] = useState([]);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [loadingData, setLoadingData] = useState(params.video_id?true:false);
  const [isEdit, setStateIsEdit] = useState(false);
  const [typeSelected, setTypeSelected] = useState(0);
  const [modalType, setModalType] = useState(null);

  const loadData = () =>{
    setLoadingData(true);

    apiClient.get(`video/${params.video_id}`)
    .then(async (res) => {
      console.log('data video', res);
      if (res.data.success) {
        const data = res.data.data;
        setInput((state) => ({...state, 
          title: data.video_name,
          desc: data.video_description,
          categorySelected: data.category_video_id,
          period: data.video_valid_days,
          price: data.video_price,
          cover_image: data.video_image[0],
          cover_video: data.video_image[1]
        }))
        setContent(data.video_content)
        console.log('data content', data.video_content)
        if(data.video_content[0].video[0].url.indexOf('https://') > -1){
          setTypeSelected(0)
        }else{
          setTypeSelected(1)
        }
        setStateIsEdit(true);
      }else{
        showNotification('Terjadi Kesalahan', res.data.error, 'danger');
        navigate(-1)
      }
      setLoadingData(false);
    })
    .catch((error) => {
      showNotification('Terjadi Kesalahan', 'Silahkan reload halaman', 'danger');
      setLoadingData(false);
      navigate(-1);
    });
  }

  useEffect(()=>{
    if(params.video_id){
      loadData()
    }
  }, [])

  useEffect(()=>{
    setContent(
      [{
        subject: '',
        video: [
          {
            name: '',
            url: '',
            duration: null
          }
        ]
      }]
    )
  }, [typeSelected])

  const addContent = () => {
    setContent([...content, {
      subject: '',
      video: [
        {
          name: '',
          url: '',
          duration: null
        }
      ] 
    }])
  }

  const deleteContent = (index) => {
    const dataTemp = [...content]
    dataTemp.splice(index, 1)
    setContent(dataTemp)
  }

  const onChangeSubject = (e, index) => {
    const dataTemp = [...content]
    dataTemp[index].subject = e.target.value
    setContent(dataTemp)
  }

  const addContentVideo = (index) => {
    const dataTemp = [...content]
    const dataVideo = [...dataTemp[index].video, {
      name: '',
      url: '',
      duration: null
    }]
    dataTemp[index].video = dataVideo
    setContent(dataTemp)
  }

  const deleteContentVideo = (index, indexVideo) => {
    const dataTemp = [...content]
    const dataVideo = [...dataTemp[index].video]
    dataVideo.splice(indexVideo, 1)
    dataTemp[index].video = dataVideo
    setContent(dataTemp)
  }

  const onChangeVideoName = (e, index, indexVideo) => {
    const dataTemp = [...content]
    const dataVideo = [...dataTemp[index].video]
    dataVideo[indexVideo].name = e.target.value
    dataTemp[index].video = dataVideo
    setContent(dataTemp)
  }

  const onChangeVideoID = (e, index, indexVideo) => {
    const dataTemp = [...content]
    const dataVideo = [...dataTemp[index].video]
    dataVideo[indexVideo].url = e.target.value
    dataTemp[index].video = dataVideo
    setContent(dataTemp)
  }

  const onChangeVideoDataYoutube = (event, index, indexVideo) => {
    const dataTemp = [...content]
    const dataVideo = [...dataTemp[index].video]
    dataVideo[indexVideo].name = event.target.getVideoData().title
    dataVideo[indexVideo].duration = event.target.getDuration()
    dataTemp[index].video = dataVideo
    setContent(dataTemp)
  }

  const onChangeVideoData = (data, index, indexVideo) => {
    console.log(data, index, indexVideo)
    const dataTemp = [...content]
    const dataVideo = [...dataTemp[index].video]
    dataVideo[indexVideo].url = data.url
    dataVideo[indexVideo].duration = data.duration
    dataTemp[index].video = dataVideo
    setContent(dataTemp)
  }

  const loadCategory = () => {
    apiClient.get(`category_video?limit=*`)
    .then(async (res) => {
      console.log('category', res);
      if (res.data.success) {
        setCategory(res.data.data.map((x) => ({ value: x.category_video_id, text: x.category_video_name })))
      }else{
        // navigate(-1)
        showNotification('Terjadi Kesalahan', res.data.error, 'danger');
      }
    })
    .catch((error) => {
      // navigate(-1)
      showNotification('Terjadi Kesalahan', 'Silahkan reload halaman', 'danger');
    });
  }

  useEffect(() => {
    loadCategory();
  }, [])

  const submit = () => {
    setModalConfirm(false);
    setLoading(true);

    let videoContent = [];
    let error = [];
    for(let i = 0; i < content.length; i++){
      if(content[i].video.length>0){
        let videoData = [];
        for(let j = 0; j < content[i].video.length; j++){
          if(content[i].video[j].name===""){
            error.push(true)
          }

          if(content[i].video[j].url===""){
            error.push(true)
          }
          
          videoData.push({
            number: j+1,
            name: content[i].video[j].name,
            url: content[i].video[j].url,
            duration: content[i].video[j].duration
          })
        }

        if(videoData.length===0){
          error.push(true)
        }

        videoContent.push(
          {
            subject: content[i].subject,
            video: videoData
          }
        )
      }else{
        error.push(true)
      }
    }

    if(videoContent.length===0){
      error.push(true)
    }

    if(error.length>0){
      showNotification('Gagal', 'Perikasi Kembali Data Anda', 'danger');
      setLoading(false);
      return false;
    }

    const dataPost = {
      video_name: input.title,
      video_description: input.desc,
      video_image: [input.cover_image, input.cover_video],
      video_price: input.price,
      video_valid_days: input.period,
      video_active_status: 1,
      category_video_id: input.categorySelected,
      video_content: videoContent
    }

    apiClient.post(`video`,  dataPost)
    .then(async (res) => {
      console.log('add video', res);
      if (res.data.success) {
        showNotification('Berhasil', res.data.success, 'success');
        navigate(-1);
      }else{
        showNotification('Error', res.data.error, 'danger');
      }
      setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
      if (err.response) {
        showNotification('Error', err.response.data.error, 'danger');
			} else {
        showNotification('Error', 'Terjadi Kesalahan', 'danger');
			}
    });
  }

  const submitEdit = () => {
    setModalConfirm(false);
    setLoading(true);

    let videoContent = [];
    let error = [];
    for(let i = 0; i < content.length; i++){
      if(content[i].video.length>0){
        let videoData = [];
        for(let j = 0; j < content[i].video.length; j++){
          if(content[i].video[j].name===""){
            error.push(true)
          }

          if(content[i].video[j].url===""){
            error.push(true)
          }
          
          videoData.push({
            number: j+1,
            name: content[i].video[j].name,
            url: content[i].video[j].url,
            duration: content[i].video[j].duration
          })
        }

        if(videoData.length===0){
          error.push(true)
        }

        videoContent.push(
          {
            subject: content[i].subject,
            video: videoData
          }
        )
      }else{
        error.push(true)
      }
    }

    if(videoContent.length===0){
      error.push(true)
    }

    if(error.length>0){
      showNotification('Gagal', 'Perikasi Kembali Data Anda', 'danger');
      setLoading(false);
      return false;
    }

    const dataPost = {
      video_name: input.title,
      video_description: input.desc,
      video_image: [input.cover_image, input.cover_video],
      video_price: input.price,
      video_valid_days: input.period,
      video_active_status: 1,
      category_video_id: input.categorySelected,
        video_content: videoContent
    }

    apiClient.put(`video/${params.video_id}`,  dataPost)
    .then(async (res) => {
      console.log('edit video', res);
      if (res.data.success) {
        showNotification('Berhasil', res.data.success, 'success');
        navigate(-1);
      }else{
        showNotification('Error', res.data.error, 'danger');
      }
      setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
      if (err.response) {
        showNotification('Error', err.response.data.error, 'danger');
			} else {
        showNotification('Error', 'Terjadi Kesalahan', 'danger');
			}
    });
  }

  if(loadingData){
    return(
      <div/>
    )
  }

  return(
    <PageWrapper title={(!isEdit?"Tambah":" Edit" )+ " Video"}>
      <SubHeader>
				<SubHeaderLeft>
          <Button color='info' isLink icon='ArrowBack' onClick={() => navigate(-1)}>
						Kembali
					</Button>
					{/* <SubheaderSeparator />
          <span className='text-muted'>Tambah Video</span> */}
          <SubheaderSeparator />
					<Breadcrumb
						list={[
							{ title: 'Video', to: '/video-content/video' },
							{ title: 'Tambah', to: '/' },
						]}
					/>
				</SubHeaderLeft>
				<SubHeaderRight>
          <Button disabled={loading} color='info' icon={loading?'':'Save'} onClick={()=>setModalConfirm(true)}>
            {

              loading ?
              <div>
                <Spinner
                  size={14}
                  style={{marginRight:10}}
                />
                Menyimpan Data...
              </div> :
              'Simpan'
            }
          </Button>
				</SubHeaderRight>
			</SubHeader>
      <Page>
        <div className='row h-100 align-content-start'>
          <div className='col-md-12'>
            <Card>
              <CardHeader>
								<CardLabel icon='VideoSettings' iconColor='success'>
									<CardTitle>Informasi Video</CardTitle>
                  <CardSubTitle>Video</CardSubTitle>
								</CardLabel>
							</CardHeader>
              <CardBody>
                <div className='row'>
                  <div className='col-md-6'>
                    <FormGroup label='Judul' isFloating>
                      <Input
                        placeholder='Judul'
                        disabled={loading}
                        value={input.title}
                        onChange={(e)=>setInput((state) => ({...state, title: e.target.value }))}
                      />
                    </FormGroup>
                  </div>
                  <div className='col-md-6'>
                    <FormGroup label='Kategori' isFloating>
                      <Select
                        ariaLabel='Kategori'
                        placeholder='Pilih Kategori'
                        value={input.categorySelected}
                        onChange={(e) => setInput((state) => ({...state, categorySelected: e.target.value }))}
                        list={category}
                        disabled={loading}
                      />
                    </FormGroup>
                  </div>
                  <div className='col-md-12'>
                    <div className='mt-3 mb-3'>
                      <div className='mb-1'>Deskripsi</div>
                      <CKEditor
                        editor={ ClassicEditor }
                        data={input.desc}
                        onChange={ ( event, editor ) => {
                          const data = editor.getData();
                          setInput((state) => ({...state, desc: data }));
                        }}
                        onReady={(editor) => {
                          editor.editing.view.change( writer => {
                            writer.setStyle( 'height', '300px', editor.editing.view.document.getRoot() );
                          });
                        }}
                        disabled={loading}
                      />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <FormGroup label='Masa Aktif (Hari)' isFloating>
                      <Input
                        placeholder='Masa Aktif'
                        type="number"
                        disabled={loading}
                        value={input.period}
                        onChange={(e)=>setInput((state) => ({...state, period: e.target.value }))}
                      />
                    </FormGroup>
                  </div>
                  <div className='col-md-6'>
                    <FormGroup label='Harga (Token)' isFloating>
                      <Input
                        placeholder='Harga'
                        type="number"
                        disabled={loading}
                        value={input.price}
                        onChange={(e)=>setInput((state) => ({...state, price: e.target.value }))}
                        min={0}
                      />
                    </FormGroup>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className='col-md-6'>
            <Card>
              <CardHeader>
								<CardLabel icon='Image' iconColor='danger'>
									<CardTitle>Cover Gambar</CardTitle>
                  <CardSubTitle>Untuk gambar pratinjau video</CardSubTitle>
								</CardLabel>
							</CardHeader>
              <CardBody>
                <div className='col-12'>
                  <ImageInput
                    disabled={loading} 
                    value={input.cover_image}
                    onChange={(data)=>setInput((state) => ({...state, cover_image: data.url }))}
                  />
								</div>
              </CardBody>
            </Card>
          </div>
          <div className='col-md-6'>
            <Card>
              <CardHeader>
								<CardLabel icon='OndemandVideo' iconColor='info'>
									<CardTitle>Video Pratinjau</CardTitle>
                  <CardSubTitle>Untuk pratinjau di halaman detail</CardSubTitle>
								</CardLabel>
							</CardHeader>
              <CardBody>
                <div className='col-12'>
                  <VideoInput
                    disabled={loading}
                    value={input.cover_video}
                    onChange={(data)=>setInput((state) => ({...state, cover_video: data.url }))}
                  />
								</div>
              </CardBody>
            </Card>
          </div>

          <div className='col-md-12'>
            <Card>
              <CardHeader>
								<CardLabel icon='SnippetFolder' iconColor='warning'>
									<CardTitle>Topik</CardTitle>
                  <CardSubTitle>Isi dari pembelajaran</CardSubTitle>
								</CardLabel>
                <SubHeaderRight>
                  <FormGroup className='mb-5'>
                    <Label>Tipe Video</Label>
                    <ChecksGroup isInline>
                      {
                        type.map((item, index) => {
                          return(
                            <Checks
                              key={'type-'+index.toString()}
                              type='checkbox'
                              label={item}
                              onChange={()=>setModalType(index)}
                              checked={typeSelected===index}
                            />
                          )
                        })
                      }
                    </ChecksGroup>
                  </FormGroup>
                </SubHeaderRight>
							</CardHeader>
              <CardBody>
                {
                  content.length===0 &&
                  <Alert
                    color='danger'
                    shadow='md'
                    isLight
                    icon='Report'
                    className='flex-nowrap'>
                    <AlertHeading>Topik Kosong</AlertHeading>
                    <p>Topik tidak boleh kosong, silahkan <b>Tambah Topik</b> untuk mengisi konten pembelajaran</p>
                  </Alert>
                }
                {
                  content.map((item, index)=> (
                    <div key={'content-'+index.toString()} className='d-flex flex-row'>
                      <CardTitle>{index+1}. </CardTitle>
                      <div className='flex-grow-1 ms-3'>
                        <Card>
                          <CardHeader>
                            <div className='col-md-8 col-sm-12'>
                              <FormGroup label='Judul Topik' isFloating>
                                <Input
                                  placeholder='Topik'
                                  disabled={loading}
                                  value={item.subject}
                                  onChange={(e) => onChangeSubject(e, index)}
                                />
                              </FormGroup>
                            </div>
                            <CardActions>
                              <Button disabled={loading} color='success' isOutline icon='Plus' onClick={()=>addContentVideo(index)}>
                                Tambah Video
                              </Button>
                              <Button disabled={loading} color='danger' isOutline icon='Delete' onClick={()=>deleteContent(index)}>
                                Hapus Topik
                              </Button>
                            </CardActions>
                          </CardHeader>
                          <hr/>
                          <CardBody>
                            <div className='row'>
                              {
                                item.video.length===0 &&
                                <Alert
                                  color='danger'
                                  shadow='md'
                                  isLight
                                  icon='Report'
                                  className='flex-nowrap'>
                                  <AlertHeading>Video Kosong</AlertHeading>
                                  <p>Cideo tidak boleh kosong, silahkan <b>Tambah Video</b> untuk mengisi Topik</p>
                                </Alert>
                              }
                              {
                                item.video.map((itemVideo, indexVideo) => {
                                  return(
                                    <div className='col-md-6 d-flex' key={`vide-${index}-${indexVideo}`}>
                                      <div className='me-2'><b>{indexVideo+1}.</b></div>
                                      {
                                        typeSelected===0 ? (
                                          <div className='row flex-grow-1'>
                                            <div className='col-sm-5 mb-5'>
                                              <VideoInput disabled={loading} value={itemVideo.url} onChange={(data)=>onChangeVideoData(data, index, indexVideo)}/>
                                            </div>
                                            <div className='col-sm-7 mb-4'>
                                              <FormGroup label='Judul Video' isFloating>
                                                <Input
                                                  placeholder='Judul Video'
                                                  disabled={loading}
                                                  value={itemVideo.name}
                                                  onChange={(e) => onChangeVideoName(e, index, indexVideo)}
                                                />
                                              </FormGroup>
                                              <div className='mt-4 mb-4'>
                                                Durasi : { itemVideo.duration?moment.utc(itemVideo.duration * 1000).format("HH:mm:ss"):'--:--' }
                                              </div>
                                              <Button disabled={loading} color='danger' isOutline icon='Delete' onClick={()=>deleteContentVideo(index, indexVideo)}>
                                                Hapus Video
                                              </Button>
                                            </div>
                                          </div>
                                        ):(
                                          <div className='row flex-grow-1'>
                                            <div className='col-sm-5 mb-5'>
                                              <FormGroup label='Video ID' isFloating className="mb-3" >
                                                <Input
                                                  placeholder='Video ID'
                                                  disabled={loading}
                                                  value={itemVideo.url}
                                                  onChange={(e) => onChangeVideoID(e, index, indexVideo)}
                                                />
                                              </FormGroup>
                                              {
                                                itemVideo.url===''?(
                                                  <img
                                                    width={"100%"}
                                                    alt=''
                                                    className='img-fluid'
                                                    src={videoPlaceholder}
                                                  />
                                                ):(
                                                  <YouTube 
                                                    videoId={itemVideo.url}
                                                    opts={{
                                                      height: 'auto',
                                                      width: '100%',
                                                    }}
                                                    onReady={(event) => {
                                                      onChangeVideoDataYoutube(event, index, indexVideo)
                                                    }} 
                                                  />
                                                )
                                              }
                                            </div>
                                            <div className='col-sm-7'>
                                              <FormGroup label='Judul Video' isFloating className="mb-3">
                                                <Input
                                                  placeholder='Judul Video'
                                                  disabled={loading}
                                                  value={itemVideo.name}
                                                  onChange={(e) => onChangeVideoName(e, index, indexVideo)}
                                                />
                                              </FormGroup>
                                              <div className='mt-4 mb-4'>
                                                Durasi : { itemVideo.duration?moment.utc(itemVideo.duration * 1000).format("HH:mm:ss"):'--:--' }
                                              </div>
                                              <Button disabled={loading} color='danger' isOutline icon='Delete' onClick={()=>deleteContentVideo(index, indexVideo)}>
                                                Hapus Video
                                              </Button>
                                            </div>
                                          </div>
                                        )
                                      }
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  ))
                }
              </CardBody>
              <CardFooter>
                <Button disabled={loading} color='success' isOutline icon='CreateNewFolder' onClick={()=>addContent()}>
                  Tambah Topik
                </Button>
              </CardFooter>
            </Card>
          </div>
        </div>
      </Page>

      <Modal
        isOpen={modalConfirm}
        setIsOpen={setModalConfirm}
        isStaticBackdrop
      >
        <ModalHeader>
          <ModalTitle>{isEdit?'Ubah':'Tambah'} Data</ModalTitle>
        </ModalHeader>
        <ModalBody>Apakah anda yakin mau {isEdit?'mengubah':'menambahkan'} data ini?</ModalBody>
        <ModalFooter>
          <Button
            color='info'
            isOutline
            className='border-0'
            onClick={() => setModalConfirm(false)}>
            Batal
          </Button>
          <Button color='info' icon='Save' onClick={isEdit?submitEdit:submit}>
            Simpan
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalType!==null?true:false}
        isStaticBackdrop
      >
        <ModalHeader>
          <ModalTitle>Ubah Tipe Video</ModalTitle>
        </ModalHeader>
        <ModalBody>Apakah anda yakin mau mengubah tipe video? Data konten video sebelumnya akan dihapus</ModalBody>
        <ModalFooter>
          <Button
            color='info'
            isOutline
            className='border-0'
            onClick={() => setModalType(null)}>
            Batal
          </Button>
          <Button color='info' icon='Save' 
            onClick={()=>{
              setTypeSelected(modalType)
              setModalType(null)
            }}
          >
            Ubah
          </Button>
        </ModalFooter>
      </Modal>
    </PageWrapper>
  )
  
}

export default VideoForm