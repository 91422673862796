import React, { useRef, useState } from "react";
import PropTypes from 'prop-types';
import axios from "axios";
import Button from "./bootstrap/Button";
import Spinner from "./bootstrap/Spinner";
import showNotification from "./extras/showNotification";

const imagePlaceholder = require('../assets/img/image_placeholder.gif');

const ImageInput = (props) => {
  const { onChange, disabled, value } = props;
  const [loading, setLoading] = useState(false);

  const inputRef = useRef();

  const [source, setSource] = useState(value);

  const handleFileChange = (event) => {
    setLoading(true);
    
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    formData.append("email", process.env.REACT_APP_CDN_EMAIL);
    formData.append("password", process.env.REACT_APP_CDN_PASSWORD);

    axios.post(process.env.REACT_APP_CDN_URL, formData)
    .then((response) => {
      if (response.data.status) {
        // berhasil
        setSource(response.data.data.url);
        onChange(response.data.data);
      } else {
        // gagal
        showNotification('Upload gagal', response.data.error, 'danger');
      }
      setLoading(false);
    })
    .catch((error) => {
      showNotification('Error', 'Terjadi kesalahan, silahkan coba lagi!', 'danger');
      setLoading(false);
    });
  };

  const handleChoose = (event) => {
    inputRef.current.click();
  };

  return (
    <div className="VideoInput">
      <input
        ref={inputRef}
        className="VideoInput_input"
        type="file"
        onChange={handleFileChange}
        accept=".jpg,.jpeg,.png,.gif"
      />
      
      <div style={{position:'relative'}}>
        {
          loading &&
          <div className="Videoinput_loader">
            <div className="Videoinput_loader_icon">
              <Spinner isSmall inButton />
              Mengupload File...
            </div>
          </div>
        }
      
        <img
          width={"100%"}
          alt=''
          className='img-fluid'
          src={source!==''?source:imagePlaceholder}
        />
      </div>
      <div className="VideoInput_footer">
        <Button
          color='success'
          icon='Upload'
          onClick={handleChoose}
          disabled={loading||disabled}
        >
          {!source?'Upload':'Ganti'} Gambar
        </Button>
      </div>
    </div>
  );
}

ImageInput.propTypes = {
	onChange: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.any
}
ImageInput.defaultProps = {
	onChange: null,
  disabled: false,
  value: ''
};

export default ImageInput