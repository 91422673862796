import React, { useEffect, useState } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../layout/SubHeader/SubHeader';
import Button from '../../components/bootstrap/Button';
import Breadcrumb from '../../components/bootstrap/Breadcrumb';
import Page from '../../layout/Page/Page';
import Card, {
  CardActions,
	CardBody,
	CardFooter,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../components/bootstrap/Card';
import Input from '../../components/bootstrap/forms/Input';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Spinner from '../../components/bootstrap/Spinner';
import Alert, { AlertHeading } from '../../components/bootstrap/Alert';
import VideoInput from '../../components/VideoInput';
import ImageInput from '../../components/ImageInput';
import Select from '../../components/bootstrap/forms/Select';
import apiClient from '../../service/AxiosInstance';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../components/bootstrap/Modal';
import showNotification from '../../components/extras/showNotification';

const VideoCategoryForm = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState({
    title:''
  })
  const [modalConfirm, setModalConfirm] = useState(false);
  const [loadingData, setLoadingData] = useState(params.video_category_id?true:false);
  const [isEdit, setStateIsEdit] = useState(false);

  const loadData = () =>{
    setLoadingData(true);

    apiClient.get(`category_video/${params.video_category_id}`)
    .then(async (res) => {
      console.log('data video', res);
      if (res.data.success) {
        const data = res.data.data;
        setInput((state) => ({...state, 
          title: data.category_video_name,
        }))
        setStateIsEdit(true);
      }else{
        showNotification('Terjadi Kesalahan', res.data.error, 'danger');
        navigate(-1)
      }
      setLoadingData(false);
    })
    .catch((error) => {
      showNotification('Terjadi Kesalahan', 'Silahkan reload halaman', 'danger');
      setLoadingData(false);
      navigate(-1);
    });
  }

  useEffect(()=>{
    if(params.video_category_id){
      loadData()
    }
  }, [])

  const submit = () => {
    setModalConfirm(false);
    setLoading(true);

    const dataPost = {
      category_video_name: input.title
    }

    apiClient.post(`category_video`,  dataPost)
    .then(async (res) => {
      console.log('add catgory', res);
      if (res.data.success) {
        showNotification('Berhasil', res.data.success, 'success');
        navigate(-1);
      }else{
        showNotification('Error', res.data.error, 'danger');
      }
      setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
      if (err.response) {
        showNotification('Error', err.response.data.error, 'danger');
			} else {
        showNotification('Error', 'Terjadi Kesalahan', 'danger');
			}
    });
  }

  const submitEdit = () => {
    setModalConfirm(false);
    setLoading(true);

    const dataPost = {
      category_video_name: input.title
    }

    apiClient.put(`category_video/${params.video_category_id}`,  dataPost)
    .then(async (res) => {
      console.log('edit video', res);
      if (res.data.success) {
        showNotification('Berhasil', res.data.success, 'success');
        navigate(-1);
      }else{
        showNotification('Error', res.data.error, 'danger');
      }
      setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
      if (err.response) {
        showNotification('Error', err.response.data.error, 'danger');
			} else {
        showNotification('Error', 'Terjadi Kesalahan', 'danger');
			}
    });
  }

  if(loadingData){
    return(
      <div/>
    )
  }

  return(
    <PageWrapper title={(!isEdit?"Tambah":" Edit" )+ " Kategori Video"}>
      <SubHeader>
				<SubHeaderLeft>
          <Button color='info' isLink icon='ArrowBack' onClick={() => navigate(-1)}>
						Kembali
					</Button>
					{/* <SubheaderSeparator />
          <span className='text-muted'>Tambah Video</span> */}
          <SubheaderSeparator />
					<Breadcrumb
						list={[
							{ title: 'Kategori Video', to: '/video-content/category' },
							{ title: 'Tambah', to: '/' },
						]}
					/>
				</SubHeaderLeft>
				<SubHeaderRight>
          <Button disabled={loading} color='info' icon={loading?'':'Save'} onClick={()=>setModalConfirm(true)}>
            {

              loading ?
              <div>
                <Spinner
                  size={14}
                  style={{marginRight:10}}
                />
                Menyimpan Data...
              </div> :
              'Simpan'
            }
          </Button>
				</SubHeaderRight>
			</SubHeader>
      <Page>
        <div className='row h-100 align-content-start'>
          <div className='col-md-12'>
            <Card>
              <CardHeader>
								<CardLabel icon='PlaylistPlay' iconColor='success'>
									<CardTitle>Kategori Video</CardTitle>
                  <CardSubTitle>Video</CardSubTitle>
								</CardLabel>
							</CardHeader>
              <CardBody>
                <div className='row'>
                  <div className='col-md-12'>
                    <FormGroup label='Nama Kategori' isFloating>
                      <Input
                        placeholder='Nama Kategori'
                        disabled={loading}
                        value={input.title}
                        onChange={(e)=>setInput((state) => ({...state, title: e.target.value }))}
                      />
                    </FormGroup>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </Page>

      <Modal
        isOpen={modalConfirm}
        setIsOpen={setModalConfirm}
        isStaticBackdrop
      >
        <ModalHeader>
          <ModalTitle>{isEdit?'Ubah':'Tambah'} Data</ModalTitle>
        </ModalHeader>
        <ModalBody>Apakah anda yakin mau {isEdit?'mengubah':'menambahkan'} data ini?</ModalBody>
        <ModalFooter>
          <Button
            color='info'
            isOutline
            className='border-0'
            onClick={() => setModalConfirm(false)}>
            Batal
          </Button>
          <Button color='info' icon='Save' onClick={isEdit?submitEdit:submit}>
            Simpan
          </Button>
        </ModalFooter>
      </Modal>
    </PageWrapper>
  )
  
}

export default VideoCategoryForm