import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../layout/SubHeader/SubHeader';
import Button from '../../components/bootstrap/Button';
import Breadcrumb from '../../components/bootstrap/Breadcrumb';
import Page from '../../layout/Page/Page';
import Card, {
	CardBody,
	CardFooter,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../components/bootstrap/Card';
import Input from '../../components/bootstrap/forms/Input';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Spinner from '../../components/bootstrap/Spinner';
import apiClient from '../../service/AxiosInstance';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../components/bootstrap/Modal';
import showNotification from '../../components/extras/showNotification';
import ImageInput from '../../components/ImageInput';
import Select from '../../components/bootstrap/forms/Select';
import Textarea from '../../components/bootstrap/forms/Textarea';
import Alert, { AlertHeading } from '../../components/bootstrap/Alert';

const PlatformForm = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState({
    title: '',
    categorySelected: '',
    icon: '',
    description: ''
  })
  const [category, setCategory] = useState([]);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [loadingData, setLoadingData] = useState(params.platform_id?true:false);
  const [isEdit, setStateIsEdit] = useState(false);
  const [link, setLink] = useState([{
    title: '',
    url: ''
  }])

  const loadData = () =>{
    setLoadingData(true);

    apiClient.get(`platform/${params.platform_id}`)
    .then(async (res) => {
      console.log('data video', res);
      if (res.data.success) {
        const data = res.data.data;
        setInput((state) => ({...state, 
          title: data.platform_name,
          icon: data.platform_image,
          description: data.platform_description,
          categorySelected: data.category_platform_id
        }))
        setLink(data.platform_link)
        setStateIsEdit(true);
      }else{
        showNotification('Terjadi Kesalahan', res.data.error, 'danger');
        navigate(-1)
      }
      setLoadingData(false);
    })
    .catch((error) => {
      showNotification('Terjadi Kesalahan', 'Silahkan reload halaman', 'danger');
      setLoadingData(false);
      navigate(-1);
    });
  }

  useEffect(()=>{
    if(params.platform_id){
      loadData()
    }
  }, [])

  const loadCategory = () => {
    apiClient.get(`category_platform?limit=*`)
    .then(async (res) => {
      console.log('category', res);
      if (res.data.success) {
        setCategory(res.data.data.map((x) => ({ value: x.category_platform_id, text: x.category_platform_name })))
      }else{
        // navigate(-1)
        showNotification('Terjadi Kesalahan', res.data.error, 'danger');
      }
    })
    .catch((error) => {
      // navigate(-1)
      showNotification('Terjadi Kesalahan', 'Silahkan reload halaman', 'danger');
    });
  }

  useEffect(() => {
    loadCategory();
  }, [])

  const submit = () => {
    setModalConfirm(false);
    setLoading(true);

    const dataPost = {
      platform_name: input.title,
      platform_description: input.description,
      platform_image: input.icon,
      platform_active_status: 1,
      category_platform_id: input.categorySelected,
      platform_link: link
    }

    apiClient.post(`platform`,  dataPost)
    .then(async (res) => {
      console.log('add platform', res);
      if (res.data.success) {
        showNotification('Berhasil', res.data.success, 'success');
        navigate(-1);
      }else{
        showNotification('Error', res.data.error, 'danger');
      }
      setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
      if (err.response) {
        showNotification('Error', err.response.data.error, 'danger');
			} else {
        showNotification('Error', 'Terjadi Kesalahan', 'danger');
			}
    });
  }

  const submitEdit = () => {
    setModalConfirm(false);
    setLoading(true);

    const dataPost = {
      platform_name: input.title,
      platform_description: input.description,
      platform_image: input.icon,
      platform_active_status: 1,
      category_platform_id: input.categorySelected,
      platform_link: link
    }

    apiClient.put(`platform/${params.platform_id}`,  dataPost)
    .then(async (res) => {
      console.log('edit video', res);
      if (res.data.success) {
        showNotification('Berhasil', res.data.success, 'success');
        navigate(-1);
      }else{
        showNotification('Error', res.data.error, 'danger');
      }
      setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
      if (err.response) {
        showNotification('Error', err.response.data.error, 'danger');
			} else {
        showNotification('Error', 'Terjadi Kesalahan', 'danger');
			}
    });
  }

  const addLink = () => {
    setLink([...link, { title: '', url: '' }])
  }

  const onChangeLink = (e, index, type) => {
    const dataTemp = [...link]
    dataTemp[index][`${type}`] = e.target.value
    setLink(dataTemp)
  }

  const deleteLink = (index) => {
    const dataTemp = [...link]
    dataTemp.splice(index, 1)
    setLink(dataTemp)
  }

  if(loadingData){
    return(
      <div/>
    )
  }
  

  return(
    <PageWrapper title={(!isEdit?"Tambah":" Edit" )+ " Platform"}>
      <SubHeader>
				<SubHeaderLeft>
          <Button color='info' isLink icon='ArrowBack' onClick={() => navigate(-1)}>
						Kembali
					</Button>
					{/* <SubheaderSeparator />
          <span className='text-muted'>Tambah Video</span> */}
          <SubheaderSeparator />
					<Breadcrumb
						list={[
							{ title: 'Platform', to: '/platform-data/category' },
							{ title: 'Tambah', to: '/' },
						]}
					/>
				</SubHeaderLeft>
				<SubHeaderRight>
          <Button disabled={loading} color='info' icon={loading?'':'Save'} onClick={()=>setModalConfirm(true)}>
            {

              loading ?
              <div>
                <Spinner
                  size={14}
                  style={{marginRight:10}}
                />
                Menyimpan Data...
              </div> :
              'Simpan'
            }
          </Button>
				</SubHeaderRight>
			</SubHeader>
      <Page>
        <div className='row h-100 align-content-start'>
          <div className='col-md-12'>
            <Card>
              <CardHeader>
								<CardLabel icon='PlaylistPlay' iconColor='success'>
									<CardTitle>Platform</CardTitle>
                  <CardSubTitle>Platform</CardSubTitle>
								</CardLabel>
							</CardHeader>
              <CardBody>
                <div className='row'>
                  <div className='col-md-3 mb-2'>
                    <ImageInput
                      disabled={loading} 
                      value={input.icon}
                      onChange={(data)=>setInput((state) => ({...state, icon: data.url }))}
                    />
                  </div>
                  <div className='col-md-9'>
                    <div className='row'>
                      <div className='col-md-6 mb-2'>
                        <FormGroup label='Nama Platform' isFloating>
                          <Input
                            placeholder='Nama Platform'
                            disabled={loading}
                            value={input.title}
                            onChange={(e)=>setInput((state) => ({...state, title: e.target.value }))}
                          />
                        </FormGroup>
                      </div>
                      <div className='col-md-6 mb-2'>
                        <FormGroup label='Kategori' isFloating>
                          <Select
                            ariaLabel='Kategori'
                            placeholder='Pilih Kategori'
                            value={input.categorySelected}
                            onChange={(e) => setInput((state) => ({...state, categorySelected: e.target.value }))}
                            list={category}
                            disabled={loading}
                          />
                        </FormGroup>
                      </div>
                      <div className='col-md-12 mb-2'>
                        <FormGroup label='Deskripsi' isFloating>
                          <Textarea
                            placeholder='Deskripsi'
                            disabled={loading}
                            value={input.description}
                            onChange={(e)=>setInput((state) => ({...state, description: e.target.value }))}
                            style={{ height: 200 }}
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className='col-md-12'>
            <Card>
              <CardHeader>
								<CardLabel icon='Link' iconColor='danger'>
									<CardTitle>Link</CardTitle>
                  <CardSubTitle>Platform</CardSubTitle>
								</CardLabel>
							</CardHeader>
              <CardBody>
                {
                  link.length===0 &&
                  <Alert
                    color='danger'
                    shadow='md'
                    isLight
                    icon='Report'
                    className='flex-nowrap'>
                    <AlertHeading>Topik Kosong</AlertHeading>
                    <p>Link tidak boleh kosong, silahkan <b>Tambah Link</b></p>
                  </Alert>
                }

                {
                  link.map((item, index)=> (
                    
                    <Card>
                      <CardBody>
                        <div key={'content-'+index.toString()} className='d-flex flex-row align-items-center'>
                          <CardTitle>{index+1}. </CardTitle>
                          <div className='flex-grow-1 ms-3'>
                            <div className='row'>
                              <div className='col-md-6 mb-2'>
                                <FormGroup label='Title' isFloating>
                                  <Input
                                    placeholder='Title'
                                    disabled={loading}
                                    value={item.title}
                                    onChange={(e) => onChangeLink(e, index, 'title')}
                                  />
                                </FormGroup>
                              </div>
                              <div className='col-md-6 mb-2'>
                                <FormGroup label='URL' isFloating>
                                  <Input
                                    placeholder='URL'
                                    disabled={loading}
                                    value={item.url}
                                    onChange={(e) => onChangeLink(e, index, 'url')}
                                  />
                                </FormGroup>
                              </div>
                            </div>
                          </div>
                          <Button disabled={loading} className="ms-3" color='danger' isOutline icon='Delete' onClick={()=>deleteLink(index)}>
                            Hapus
                          </Button>
                        </div>
                      </CardBody>
                    </Card>
                  ))
                }
              </CardBody>
              <CardFooter>
                <Button disabled={loading} color='success' isOutline icon='CreateNewFolder' onClick={()=>addLink()}>
                  Tambah Link
                </Button>
              </CardFooter>
            </Card>
          </div>
        </div>
      </Page>

      <Modal
        isOpen={modalConfirm}
        setIsOpen={setModalConfirm}
        isStaticBackdrop
      >
        <ModalHeader>
          <ModalTitle>{isEdit?'Ubah':'Tambah'} Data</ModalTitle>
        </ModalHeader>
        <ModalBody>Apakah anda yakin mau {isEdit?'mengubah':'menambahkan'} data ini?</ModalBody>
        <ModalFooter>
          <Button
            color='info'
            isOutline
            className='border-0'
            onClick={() => setModalConfirm(false)}>
            Batal
          </Button>
          <Button color='info' icon='Save' onClick={isEdit?submitEdit:submit}>
            Simpan
          </Button>
        </ModalFooter>
      </Modal>
    </PageWrapper>
  )
  
}

export default PlatformForm