import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../layout/SubHeader/SubHeader';
import Button from '../../components/bootstrap/Button';
import Breadcrumb from '../../components/bootstrap/Breadcrumb';
import Page from '../../layout/Page/Page';
import Card, {
	CardBody,
	CardHeader,
	CardTitle,
} from '../../components/bootstrap/Card';
import Spinner from '../../components/bootstrap/Spinner';
import apiClient from '../../service/AxiosInstance';
import showNotification from '../../components/extras/showNotification';
import { separator } from '../../helpers/helpers';
import Badge from '../../components/bootstrap/Badge';

const PlatfromDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [loadingAction, setLoadingAction] = useState(false);

  const loadData = () => {
    setLoading(true);

    apiClient.get(`platform/${params.platform_id}`)
    .then(async (res) => {
      console.log('data', res);
      if (res.data.success) {
        setData(res.data.data);
      }else{
        showNotification('Error', res.data.error, 'danger');
      }
      setLoading(false);
    })
    .catch((error) => {
      setLoading(false);
      if (error.response) {
        showNotification('Error', error.response.data.error, 'danger');
			} else {
        showNotification('Error', 'Terjadi Kesalahan', 'danger');
			}
    });
  }

  useEffect(()=>{
    loadData()
  }, [])

  const changeStatus = () => {
    const status = data.platform_active_status_name==='active'?'inactivate':'activate';

    setLoadingAction(true)
    apiClient.put(`platform/${params.platform_id}/${status}`)
    .then(async (res) => {
      if (res.data.success) {
        setData({...data, platform_active_status_name: status==='activate'?'active':'inactive'});
        showNotification('Success', res.data.success, 'success');
      }else{
        showNotification('Error', res.data.error, 'danger');
      }
      setLoadingAction(false);
    })
    .catch((error) => {
      setLoadingAction(false);
      if (error.response) {
        showNotification('Error', error.response.data.error, 'danger');
			} else {
        showNotification('Error', 'Terjadi Kesalahan', 'danger');
			}
    });
  }

  if(loading){
    return null
  }

  return(
    <PageWrapper title="Detail Video">
      <SubHeader>
				<SubHeaderLeft>
          <Button color='info' isLink icon='ArrowBack' onClick={() => navigate(-1)}>
						Kembali
					</Button>
          <SubheaderSeparator />
					<Breadcrumb
						list={[
							{ title: 'Video', to: '/video-content/video' },
							{ title: 'Detail', to: '/' },
						]}
					/>
				</SubHeaderLeft>
				<SubHeaderRight>
          <Button disabled={loading || loadingAction} color={data.platform_active_status_name==='active'?'danger':'success'} icon={'Info'} onClick={()=>changeStatus()}>
            {
              loadingAction?(
                <span>
                  <Spinner
                    size={14}
                    style={{marginRight:10}}
                  />
                  Menyimpan Data...
                </span>
              ):(
                <span>{data.platform_active_status_name==='active'?'Nonaktifkan':'Aktifkan'}</span>
              )
            }
          </Button>
          <Button disabled={loading} color='info' icon={'Edit'} 
            onClick={()=>navigate(`/platform-data/platform/edit/${data.platform_id}`)}
          >
            Ubah Data
          </Button>
				</SubHeaderRight>
			</SubHeader>
      <Page>
        <div className='row h-100 align-content-start'>
          <div className='col-md-4'>
           <Card className='shadow-3d-primary'>
							<CardBody>
								<div className='row g-1'>
                  <img src={data.platform_image} className='rounded' alt='' />
                  <div className='col-12 mt-2'>
                    <div className='text-muted'>
                      Judul
                    </div>
                    <div className='fw-bold fs-5 mb-0'>
                      {data.platform_name}
                    </div>
                  </div>
                  {/* <div className='col-12'>
                    <div className='text-muted'>
                      Kategori
                    </div>
                    <div className='fw-bold fs-5 mb-0'>
                      {data.category_platform.category_platform_name}
                    </div>
                  </div> */}
                  <div className='col-12'>
                    <div className='text-muted'>
                      Status
                    </div>
                    <div className='fw-bold fs-5 mb-0'>
                    <Badge color={data.platform_active_status_name==='active'?'success':'danger'}>{data.platform_active_status_name}</Badge>
                    </div>
                  </div>
								</div>
							</CardBody>
						</Card>

            <Card>
							<CardHeader>
                <CardTitle>Link</CardTitle>
							</CardHeader>
							<CardBody>
                {
                  data.platform_link.map((item, index)=>{
                    return(
                      <div className='d-flex mb-2' key={'link-'+index.toString()}>
                        <div style={{width:20}}>{index+1}.</div>
                        <div className='flex-grow-1'>
                          <strong>{item.title}</strong>
                          <div>
                            <a className='text-danger' href={item.url} rel="noreferrer" target="_blank">{item.url}</a>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
							</CardBody>
						</Card>
          </div>
          <div className='col-md-8'>
            <Card>
							<CardHeader>
                <CardTitle>Deskripsi</CardTitle>
							</CardHeader>
							<CardBody>
                <div style={{whiteSpace: "pre-wrap"}}>{data.platform_description}</div>
							</CardBody>
						</Card>
          </div>
        </div>
      </Page>
    </PageWrapper>
  )
}

export default PlatfromDetail