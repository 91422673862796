import React, { useEffect, useState } from 'react';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Card, {
	CardBody,
  CardHeader,
  CardTitle,
  CardLabel
} from '../../components/bootstrap/Card';
import Button from '../../components/bootstrap/Button';
import PaginationButtons, { PER_COUNT } from '../../components/PaginationButtons';

const User = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['10']);

  useEffect(() => {
    setData(['1','1','1','1','1','1','1','1','1','1','1','1','1','1','1','1','1']);
  }, [])

	return (
		<PageWrapper title='Pengguna'>
			<Page container='fluid'>
				<Card stretch>
          <CardHeader borderSize={1}>
            <CardLabel icon='PersonWorkspace' iconColor='info'>
              <CardTitle>Daftar Pengguna</CardTitle>
            </CardLabel>
          </CardHeader>
					<CardBody className='table-responsive' isScrollable>
            <table className='table table-modern'>
              <thead>
                <tr>
                  <th>Customer</th>
                  <th>Assigned to</th>
                  <th>Service</th>
                  <th>Duration</th>
                  <th>Payment</th>
                  <th>Status</th>
                  <th>Aksi</th>
                </tr>
              </thead>
              <tbody>
                {
                  data.map((item) => (
                    <tr>
                      <td>{item}</td>
                      <td>Assigned to</td>
                      <td>Service</td>
                      <td>Duration</td>
                      <td>Payment</td>
                      <td>Status</td>
                      <td>
                        <Button
                          color='warning'
                          className="border-light"
                          icon='Edit'
                        >
                          Edit
                        </Button>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
					</CardBody>
          <PaginationButtons
            data={data}
            label='items'
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            perPage={perPage}
            setPerPage={setPerPage}
          />
				</Card>
			</Page>
		</PageWrapper>
	);
};

export default User;