
export const dashboardMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		subMenu: null,
	},
};

export const authPages = {
	auth: {
		id: 'auth',
		text: 'Auth Pages',
		icon: 'Extension',
	},
	login: {
		id: 'login',
		text: 'Login',
		path: 'login',
		icon: 'Login',
	},

	page404: {
		id: 'Page404',
		text: '404 Page',
		path: '404',
		icon: 'ReportGmailerrorred',
	},
};

export const layoutMenu = {
	layoutTypes: {
		id: 'layoutTypes',
		text: 'Content',
	},
	user: {
		id: 'userList',
		text: 'Pengguna',
		path: 'user',
		icon: 'PersonWorkspace',
	},
	videoContent: {
		id: 'videoContent',
		text: 'Konten Video',
		path: 'video-content',
		icon: 'VideoSettings',
		subMenu: {
			videoCategory: {
				id: 'videoCategory',
				text: 'Kategori Video',
				path: 'video-content/category',
				icon: 'PlaylistPlay',
			},
			videoList: {
				id: 'videoList',
				text: 'Video',
				path: 'video-content/video',
				icon: 'SlowMotionVideo',
			}
		}
	},
	platformContent: {
		id: 'platformContent',
		text: 'Data Platform',
		path: 'platform-data',
		icon: 'DevicesOther',
		subMenu: {
			videoCategory: {
				id: 'platformCategory',
				text: 'Kategori Platform',
				path: 'platform-data/category',
				icon: 'PlaylistPlay',
			},
			videoList: {
				id: 'platformList',
				text: 'Platform',
				path: 'platform-data/platform',
				icon: 'Apps',
			}
		}
	}
};