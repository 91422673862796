import React, { lazy } from 'react';
import { dashboardMenu, authPages, layoutMenu } from '../menu';
import Login from '../pages/presentation/auth/Login';

import User from '../pages/user/User';
import Video from '../pages/video/Video';
import VideoForm from '../pages/video/VideoForm';
import VideoDetail from '../pages/video/VideoDetail';
import VideoCategory from '../pages/video-category/VideoCategory';
import VideoCategoryForm from '../pages/video-category/VideoCategoryForm';

import PlatformCategory from '../pages/platform-category/PlatformCategory';
import PlatformCategoryForm from '../pages/platform-category/PlatformCategoryForm';

import Platform from '../pages/platform/Platform';
import PlatfromDetail from '../pages/platform/PlatformDetail';
import PlatformForm from '../pages/platform/PlatformForm';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
};
const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};

const presentation = [
	/**
	 * Landing
	 */
	{
		path: dashboardMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
		exact: true,
	},
	{
		path: authPages.page404.path,
		element: <AUTH.PAGE_404 />,
		exact: true,
	},
	{
		path: authPages.login.path,
		element: <Login />,
		exact: true,
	},

	/** ************************************************** */
	
	/**
	* PAGE SMETER
	*/

	{
		path: layoutMenu.user.path,
		element: <User/>,
		exact: true,
	},
	{
		path: '/video-content/video',
		element: <Video/>,
		exact: true,
	},
	{
		path: '/video-content/video/add',
		element: <VideoForm/>,
		exact: true,
	},
	{
		path: '/video-content/video/detail/:video_id',
		element: <VideoDetail/>,
		exact: true,
	},
	{
		path: '/video-content/video/edit/:video_id',
		element: <VideoForm/>,
		exact: true,
	},
	{
		path: '/video-content/category',
		element: <VideoCategory/>,
		exact: true,
	},
	{
		path: '/video-content/category/add',
		element: <VideoCategoryForm/>,
		exact: true,
	},
	{
		path: '/video-content/category/edit/:video_category_id',
		element: <VideoCategoryForm/>,
		exact: true,
	},
	{
		path: '/platform-data/category',
		element: <PlatformCategory/>,
		exact: true,
	},
	{
		path: '/platform-data/category/add',
		element: <PlatformCategoryForm/>,
		exact: true,
	},
	{
		path: '/platform-data/category/edit/:platform_category_id',
		element: <PlatformCategoryForm/>,
		exact: true,
	},
	{
		path: '/platform-data/platform',
		element: <Platform/>,
		exact: true,
	},
	{
		path: '/platform-data/platform/detail/:platform_id',
		element: <PlatfromDetail/>,
		exact: true,
	},
	{
		path: '/platform-data/platform/add',
		element: <PlatformForm/>,
		exact: true,
	},
	{
		path: '/platform-data/platform/edit/:platform_id',
		element: <PlatformForm/>,
		exact: true,
	}
];
const contents = [...presentation];

export default contents;
