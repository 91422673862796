import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/id';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Card, {
	CardBody,
  CardHeader,
  CardTitle,
  CardLabel,
  CardActions
} from '../../components/bootstrap/Card';
import Button from '../../components/bootstrap/Button';
import PaginationButtons, { PER_COUNT } from '../../components/PaginationButtons';
import LoaderTable from '../common/LoaderTable';
import apiClient from '../../service/AxiosInstance';
import showNotification from '../../components/extras/showNotification';
import Badge from '../../components/bootstrap/Badge';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../components/bootstrap/Dropdown';

const limit = 10;

const Platform = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT[limit.toString()]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(1);
  const [category, setCategory] = useState([{ category_platform_id: '', category_platform_name: 'Semua Kategori' }])
  const [categorySelected, setCategorySelected] = useState(0)

  const getData = (pageData = 1) => {
    setLoading(true);

    apiClient.get(`platform`, {
      params: {
        page: pageData,
        limit: limit,
        category_platform_id: category[categorySelected].category_platform_id
      }
    })
    .then(async (res) => {
      console.log('data', res);
      if (res.data.success) {
        setData(res.data.data);
        setCurrentPage(res.data.page);
        setTotal(res.data.total);
      }else{
        showNotification('Error', res.data.error, 'danger');
      }
      setLoading(false);
    })
    .catch((error) => {
      setLoading(false);
      if (error.response) {
        showNotification('Error', error.response.data.error, 'danger');
			} else {
        showNotification('Error', 'Terjadi Kesalahan', 'danger');
			}
    })
  }

  const loadCategory = () => {
    apiClient.get(`category_platform`, {
      params: {
        limit: '*',
        category_platform_active_status_id: 1
      }
    })
    .then(async (res) => {
      if (res.data.success) {
        setCategory([...category, ...res.data.data]);
      }
    })
  }

  useEffect(() => {
    loadCategory()
  }, [])

  useEffect(()=>{
    getData();
  }, [categorySelected])

  const btnPagination = (e) => {
    getData(e)
    setCurrentPage(e)
  }

	return (
		<PageWrapper title='Daftar Platform'>
			<Page container='fluid'>
				<Card stretch>
          <CardHeader borderSize={1}>
            <CardLabel icon='PlaylistPlay' iconColor='info'>
              <CardTitle>Daftar Platform</CardTitle>
            </CardLabel>
            <CardActions>
              <Dropdown isButtonGroup>
                <Button disabled color='info' isLight>
                  { category[categorySelected].category_platform_name }
                </Button>
								<DropdownToggle>
									<Button color='info' isLight />
								</DropdownToggle>
								<DropdownMenu isAlignmentEnd className="dropdown-menu-custom">
                  {
                    category.map((item, index) => {
                      return(
                        <DropdownItem key={'category'+index.toString()} onClick={()=>setCategorySelected(index)}>
                          <span>{item.category_platform_name}</span>
                        </DropdownItem>
                      )
                    })
                  }
									
								</DropdownMenu>
							</Dropdown>
              <Button
                color='success'
                icon='Plus'
                tag='a'
                to='/platform-data/platform/add'>
                  Tambah Platform
              </Button>
            </CardActions>
          </CardHeader>
					<CardBody className='table-responsive' isScrollable>
            <LoaderTable loading={loading}/>

            <table className='table table-modern'>
              <thead>
                <tr>
                  <th>Icon</th>
                  <th className='text-center'>Nama</th>
                  <th className='text-center'>Katgeori</th>
                  <th className='text-center'>status</th>
                  <th className='text-center'>Created Date</th>
                  <th className='text-center'>Aksi</th>
                </tr>
              </thead>
              {
                data.length === 0 ? (
                  <div className="mt-5 text-center" style={{position:'absolute', left:0, right:0}}>Data tidak ditemukan</div>
                ) : (
                  <tbody>
                    {
                      data.map((item, index) => (
                        <tr key={`list-${index}`}>
                          <td><img src={item.platform_image} alt="" height={'35px'}/> </td>
                          <td>{item.platform_name}</td>
                          <td>{item.category_platform.category_platform_name}</td>
                          <td className='text-center'><Badge color={item.platform_active_status_name==='active'?'success':'danger'}>{item.platform_active_status_name}</Badge></td>
                          <td className='text-center'>{moment(item.category_video_created_datetime).format('DD MMMM YYYY HH:mm:ss')}</td>
                          <td className='text-center'>
                            <Button
                              color='info'
                              icon='Eye'
                              tag='a'
                              to={`/platform-data/platform/detail/${item.platform_id}`}
                              className='me-2'
                            >
                              Detail
                            </Button>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                )
              }
            </table>
					</CardBody>
          <PaginationButtons
            data={Array(total)}
            label='items'
            setCurrentPage={(e) => btnPagination(e)}
            currentPage={currentPage}
            perPage={perPage}
            setPerPage={setPerPage}
          />
				</Card>
			</Page>
		</PageWrapper>
	)
}

export default Platform;