import React from 'react'
import PropTypes from 'prop-types';
import Spinner from '../../components/bootstrap/Spinner';

const LoaderTable = ({loading}) => {
  if(loading){
    return(
      <div style={{position:'absolute', left:0, right:0, top:0, bottom:0, backgroundColor:'#0000002e', borderRadius:25}} className="d-flex flex-column justify-content-center">
        <div className='d-flex flex-row align-items-center justify-content-center'>
          <Spinner className="me-2"/>
          <div>Memudat Data...</div>
        </div>
      </div>
    )
  }

  return null
}

LoaderTable.propTypes = {
	loading: PropTypes.bool,
};
LoaderTable.defaultProps = {
	loading: false,
};

export default LoaderTable