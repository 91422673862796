import React from 'react';
import { dashboardMenu, authPages, layoutMenu } from '../menu';
import DefaultHeader from '../pages/common/Headers/DefaultHeader';

const headers = [
	{ path: authPages.login.path, element: null, exact: true },
	{ path: authPages.page404.path, element: null, exact: true },
	{ path: dashboardMenu.dashboard.path, element: <DefaultHeader title={dashboardMenu.dashboard.text} />, exact: true },
	{ path: layoutMenu.user.path, element: <DefaultHeader title={layoutMenu.user.text} /> },
	{ path: '/video-content/video', element: <DefaultHeader title={'Video'} /> },
	{ path: '/video-content/video/add', element: <DefaultHeader title='Tambah Video' /> },
	{ path: '/video-content/video/detail/:video_id', element: <DefaultHeader title='Detail Video' /> },
	{ path: '/video-content/video/edit/:video_id', element: <DefaultHeader title='Edit Video' /> },
	{ path: '/video-content/category', element: <DefaultHeader title='Kategori Video' /> },
	{ path: '/video-content/category/add', element: <DefaultHeader title='Tambah Kategori Video' /> },
	{ path: '/video-content/category/edit/:video_category_id', element: <DefaultHeader title='Edit Kategori Video' /> },
	{ path: '/platform-data/category', element: <DefaultHeader title='Kategori Platform' /> },
	{ path: '/platform-data/category/add', element: <DefaultHeader title='Tambah Kategori Platform' /> },
	{ path: '/platform-data/category/edit/:platform_category_id', element: <DefaultHeader title='Edit Kategori Platform' /> },
	{ path: '/platform-data/platform', element: <DefaultHeader title='Platform' /> },
	{ path: '/platform-data/platform/detail/:platform_id', element: <DefaultHeader title='Detail Platform' /> },
	{ path: '/platform-data/platform/add', element: <DefaultHeader title='Tambah Platform' /> },
	{ path: '/platform-data/platform/edit/:platform_id', element: <DefaultHeader title='Edit Platform' /> },
	{
		path: `*`,
		element: <DefaultHeader/>,
	},
];

export default headers;
