import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import YouTube from 'react-youtube';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../layout/SubHeader/SubHeader';
import Button from '../../components/bootstrap/Button';
import Breadcrumb from '../../components/bootstrap/Breadcrumb';
import Page from '../../layout/Page/Page';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../components/bootstrap/Card';
import Spinner from '../../components/bootstrap/Spinner';
import apiClient from '../../service/AxiosInstance';
import showNotification from '../../components/extras/showNotification';
import { separator } from '../../helpers/helpers';
import Accordion, { AccordionItem } from '../../components/bootstrap/Accordion';
import Badge from '../../components/bootstrap/Badge';

const VideoDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [loadingAction, setLoadingAction] = useState(false);

  const loadData = () => {
    setLoading(true);

    apiClient.get(`video/${params.video_id}`)
    .then(async (res) => {
      console.log('data', res);
      if (res.data.success) {
        setData(res.data.data);
      }else{
        showNotification('Error', res.data.error, 'danger');
      }
      setLoading(false);
    })
    .catch((error) => {
      setLoading(false);
      if (error.response) {
        showNotification('Error', error.response.data.error, 'danger');
			} else {
        showNotification('Error', 'Terjadi Kesalahan', 'danger');
			}
    });
  }

  useEffect(()=>{
    loadData()
  }, [])

  const changeStatus = () => {
    const status = data.video_active_status_name==='active'?'inactivate':'activate';

    setLoadingAction(true)
    apiClient.put(`video/${params.video_id}/${status}`)
    .then(async (res) => {
      if (res.data.success) {
        setData({...data, video_active_status_name: status==='activate'?'active':'inactive'});
        showNotification('Success', res.data.success, 'success');
      }else{
        showNotification('Error', res.data.error, 'danger');
      }
      setLoadingAction(false);
    })
    .catch((error) => {
      setLoadingAction(false);
      if (error.response) {
        showNotification('Error', error.response.data.error, 'danger');
			} else {
        showNotification('Error', 'Terjadi Kesalahan', 'danger');
			}
    });
  }

  if(loading){
    return null
  }

  return(
    <PageWrapper title="Detail Video">
      <SubHeader>
				<SubHeaderLeft>
          <Button color='info' isLink icon='ArrowBack' onClick={() => navigate(-1)}>
						Kembali
					</Button>
          <SubheaderSeparator />
					<Breadcrumb
						list={[
							{ title: 'Video', to: '/video-content/video' },
							{ title: 'Detail', to: '/' },
						]}
					/>
				</SubHeaderLeft>
				<SubHeaderRight>
          <Button disabled={loading || loadingAction} color={data.video_active_status_name==='active'?'danger':'success'} icon={'Info'} onClick={()=>changeStatus()}>
            {
              loadingAction?(
                <span>
                  <Spinner
                    size={14}
                    style={{marginRight:10}}
                  />
                  Menyimpan Data...
                </span>
              ):(
                <span>{data.video_active_status_name==='active'?'Nonaktifkan':'Aktifkan'}</span>
              )
            }
          </Button>
          <Button disabled={loading} color='info' icon={'Edit'} 
            onClick={()=>navigate(`/video-content/video/edit/${data.video_id}`)}
          >
            Ubah Data
          </Button>
				</SubHeaderRight>
			</SubHeader>
      <Page>
        <div className='row h-100 align-content-start'>
          <div className='col-md-4'>
           <Card className='shadow-3d-primary'>
							<CardBody>
								<div className='row g-1'>
                  <img src={data.video_image[0]} className='rounded' alt='' />
                  <div className='col-12 mt-2'>
                    <div className='text-muted'>
                      Judul
                    </div>
                    <div className='fw-bold fs-5 mb-0'>
                      {data.video_name}
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='text-muted'>
                      Kategori
                    </div>
                    <div className='fw-bold fs-5 mb-0'>
                      {data.category_video.category_video_name}
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='text-muted'>
                      Durasi Langganan
                    </div>
                    <div className='fw-bold fs-5 mb-0'>
                      {separator(data.video_valid_days)} Hari
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='text-muted'>
                      Harga
                    </div>
                    <div className='fw-bold fs-5 mb-0'>
                      {separator(data.video_price)} Token
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='text-muted'>
                      Status
                    </div>
                    <div className='fw-bold fs-5 mb-0'>
                    <Badge color={data.video_active_status_name==='active'?'success':'danger'}>{data.video_active_status_name}</Badge>
                    </div>
                  </div>
								</div>
							</CardBody>
						</Card>

            <Card>
							<CardHeader>
                <CardTitle>Deskripsi</CardTitle>
							</CardHeader>
							<CardBody>
                <div dangerouslySetInnerHTML={{__html:data.video_description}} />
							</CardBody>
						</Card>
          </div>
          <div className='col-md-8'>
            <Card>
              <CardHeader>
								<CardLabel icon='OndemandVideo' iconColor='info'>
									<CardTitle>Video Pratinjau</CardTitle>
                  <CardSubTitle>Pratinjau di halaman detail</CardSubTitle>
								</CardLabel>
							</CardHeader>
              <CardBody>
                <div className='col-12'>
                  <video
                    className="VideoInput_video rounded"
                    width={"100%"}
                    height={"auto"}
                    controls
                    src={data.video_image[1]}
                  >
                    <track kind="captions"/>
                  </video>
								</div>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
								<CardLabel icon='SnippetFolder' iconColor='warning'>
									<CardTitle>Topik</CardTitle>
                  <CardSubTitle>Isi dari pembelajaran</CardSubTitle>
								</CardLabel>
							</CardHeader>
              <CardBody>
                <Accordion id='accSample-shadow-4' shadow='lg'>
                  {
                    data.video_content.map((item, index)=>{
                      return(
                        <AccordionItem
                          key={`content-${index}`}
                          id={`content-${index}`}
                          title={item.subject}
                        >
                          <div className='row'>
                            {
                              item.video.map((itemVideo, indexVideo) => {
                                return(
                                  <div className='col-md-6 d-flex mb-3' key={`video-${index}-${indexVideo}`}>
                                    <div className='row me-1 ms-1'>
                                      {
                                        itemVideo.url.indexOf('https://') > -1 ? (
                                          <video
                                            className="VideoInput_video rounded"
                                            width={"100%"}
                                            height={"auto"}
                                            controls
                                            src={itemVideo.url}
                                          >
                                            <track kind="captions"/>
                                          </video>
                                        ) : (
                                          <YouTube 
                                            videoId={itemVideo.url}
                                            opts={{
                                              height: 'auto',
                                              width: '100%',
                                            }}
                                          />
                                        )
                                      }
                                      
                                      <strong className='mt-2'>{itemVideo.name}</strong>
                                      <div>Durasi : {moment.utc(itemVideo.duration * 1000).format("HH:mm:ss")}</div>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </AccordionItem>
                      )
                    })
                  }
                </Accordion>
              </CardBody>
            </Card>
          </div>
        </div>
      </Page>
    </PageWrapper>
  )
}

export default VideoDetail