import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/id';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Card, {
	CardBody,
  CardHeader,
  CardTitle,
  CardLabel,
  CardActions
} from '../../components/bootstrap/Card';
import Button from '../../components/bootstrap/Button';
import PaginationButtons, { PER_COUNT } from '../../components/PaginationButtons';
import LoaderTable from '../common/LoaderTable';
import apiClient from '../../service/AxiosInstance';
import showNotification from '../../components/extras/showNotification';
import Badge from '../../components/bootstrap/Badge';
import Spinner from '../../components/bootstrap/Spinner';

const limit = 10;

const PlatformCategory = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT[limit.toString()]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(1);
  const [loadingAction, setLoadingAction] = useState(false);

  const getData = (pageData = 1) => {
    setLoading(true);

    apiClient.get(`category_platform`, {
      params: {
        page: pageData,
        limit: limit
      }
    })
    .then(async (res) => {
      console.log('data', res);
      if (res.data.success) {
        setData(res.data.data);
        setCurrentPage(res.data.page);
        setTotal(res.data.total);
      }else{
        showNotification('Error', res.data.error, 'danger');
      }
      setLoading(false);
    })
    .catch((error) => {
      setLoading(false);
      if (error.response) {
        showNotification('Error', error.response.data.error, 'danger');
			} else {
        showNotification('Error', 'Terjadi Kesalahan', 'danger');
			}
    })
  }

  useEffect(() => {
    getData();
  }, [])

  const btnPagination = (e) => {
    getData(e)
    setCurrentPage(e)
  }

  const changeStatus = (category_platform_active_status_name, category_platform_id) => {
    const status = category_platform_active_status_name==='active'?'inactivate':'activate';

    setLoadingAction(true)
    apiClient.put(`category_platform/${category_platform_id}/${status}`)
    .then(async (res) => {
      if (res.data.success) {
        showNotification('Success', res.data.success, 'success');
        getData();
      }else{
        showNotification('Error', res.data.error, 'danger');
      }
      setLoadingAction(false);
    })
    .catch((error) => {
      setLoadingAction(false);
      if (error.response) {
        showNotification('Error', error.response.data.error, 'danger');
			} else {
        showNotification('Error', 'Terjadi Kesalahan', 'danger');
			}
    });
  }

	return (
		<PageWrapper title='Kategori Platform'>
			<Page container='fluid'>
				<Card stretch>
          <CardHeader borderSize={1}>
            <CardLabel icon='PlaylistPlay' iconColor='info'>
              <CardTitle>Daftar Kategori</CardTitle>
            </CardLabel>
            <CardActions>
              <Button
                color='success'
                icon='Plus'
                tag='a'
                to='/platform-data/category/add'>
                  Tambah Kategori
              </Button>
            </CardActions>
          </CardHeader>
					<CardBody className='table-responsive' isScrollable>
            <LoaderTable loading={loading}/>

            <table className='table table-modern'>
              <thead>
                <tr>
                  <th>Icon</th>
                  <th className='text-center'>Nama</th>
                  <th className='text-center'>status</th>
                  <th className='text-center'>Created Date</th>
                  <th className='text-center'>Aksi</th>
                </tr>
              </thead>
              {
                data.length === 0 ? (
                  <div className="mt-5 text-center" style={{position:'absolute', left:0, right:0}}>Data tidak ditemukan</div>
                ) : (
                  <tbody>
                    {
                      data.map((item, index) => (
                        <tr key={`list-${index}`}>
                          <td><img src={item.category_platform_image} alt="" height={'40px'}/> </td>
                          <td>{item.category_platform_name}</td>
                          <td className='text-center'><Badge color={item.category_platform_active_status_name==='active'?'success':'danger'}>{item.category_platform_active_status_name}</Badge></td>
                          <td className='text-center'>{moment(item.category_video_created_datetime).format('DD MMMM YYYY HH:mm:ss')}</td>
                          <td className='text-center'>
                            <Button
                              color='info'
                              icon='Edit'
                              tag='a'
                              to={`/platform-data/category/edit/${item.category_platform_id}`}
                              className='me-2'
                            >
                              Edit
                            </Button>
                            <Button disabled={loading || loadingAction} color={item.category_platform_active_status_name==='active'?'danger':'success'} icon={'Info'} onClick={()=>changeStatus(item.category_platform_active_status_name, item.category_platform_id)}>
                              {
                                loadingAction?(
                                  <span>
                                    <Spinner
                                      size={14}
                                      style={{marginRight:10}}
                                    />
                                    Menyimpan Data...
                                  </span>
                                ):(
                                  <span>{item.category_platform_active_status_name==='active'?'Nonaktifkan':'Aktifkan'}</span>
                                )
                              }
                            </Button>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                )
              }
            </table>
					</CardBody>
          <PaginationButtons
            data={Array(total)}
            label='items'
            setCurrentPage={(e) => btnPagination(e)}
            currentPage={currentPage}
            perPage={perPage}
            setPerPage={setPerPage}
          />
				</Card>
			</Page>
		</PageWrapper>
	)
}

export default PlatformCategory;