import axios from 'axios';
import Cookies from 'js-cookie';
import { encrypt, decrypt } from './Cryptograph'

// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  })
  
  failedQueue = [];
}

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_URL_API,
    headers: {
      'Content-Type': 'application/json'
    }
})


apiClient.interceptors.response.use(undefined, error => {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    if (isRefreshing) {
      try {
        const token = new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        });
        originalRequest.headers.Authorization = 'Bearer ' + token;
        return apiClient.request(originalRequest);
      } catch (err) {
        return Promise.reject(err);
      }
    }

    originalRequest._retry = true;
    isRefreshing = true;

    const refreshToken = decrypt(Cookies.get("refreshToken"));
    const token = decrypt(Cookies.get("token"));

    return new Promise((resolve, reject) => {
      
      apiClient.post('refresh_token', { refresh_token: refreshToken }, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .then((response) => {
        Cookies.set("token", encrypt(response.data.data.token), { expires: 1 });
     		Cookies.set("refreshToken", encrypt(response.data.data.refreshToken), { expires: 1 });

        apiClient.defaults.headers.common.Authorization = 'Bearer ' + response.data.data.token;
        originalRequest.headers.Authorization = 'Bearer ' + response.data.data.token;
        processQueue(null, response.data.data.token);
        resolve(apiClient(originalRequest));
      })
      .catch((err) => {
        processQueue(err, null);
        reject(err);
      })
      .then(() => { isRefreshing = false })

    })
  }
})

export default apiClient;