import { AES, enc } from "crypto-js"
export const Securitykey = "Sm3teR@021";

export const decrypt = (data) => {
  if(data){
    const bytes = AES.decrypt(data, Securitykey);
    const decryptedData = bytes.toString(enc.Utf8);
    return decryptedData;
  }
  
  return null
}

export const encrypt = (data) => {
  return AES.encrypt(data, Securitykey).toString();
}